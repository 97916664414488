<template>
  <div class="advice">
    <el-tabs
      v-model="tagName"
      type="card"
      class="demo-tabs"
      @tab-click="handleClick"
    >
      <el-tab-pane label="长期医嘱" name="longIerm">
        <longIerm></longIerm>
      </el-tab-pane>
      <el-tab-pane label="预开医嘱" name="preOpening">
        <preOpening></preOpening>
      </el-tab-pane>
      <el-tab-pane label="常用组套" name="setUp">
        <setUp></setUp>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref } from 'vue'
import longIerm from './advice/longIerm.vue'
import preOpening from './advice/preOpening.vue'
import setUp from './advice/setUp.vue'
export default {
  name: 'advice',
  components: {
    longIerm,
    preOpening,
    setUp,
  },
  setup() {
    const tagName = ref('longIerm')

    const handleClick = () => {
      // console.log(tab)
    }

    return {
      tagName,
      handleClick,
    }
  },
}
</script>

<style scoped lang="scss"></style>
